import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppDispatch, AppThunk } from '@/state/store';
import { ICompany } from './interfaces';
import companyData from '../../db/company-data.json';

interface CompanyState {
  list: ICompany[];
  filteredList: ICompany[];
  filteredListIsFavorites: boolean;
  favorites: number[];
}

const initialState: CompanyState = {
  list: companyData,
  filteredList: [],
  filteredListIsFavorites: false,
  favorites: localStorage.getItem('favorites')
    ? JSON.parse(localStorage.getItem('favorites') as string)
    : [],
};

const saveFavorites = (data: number[]) => {
  localStorage.setItem('favorites', JSON.stringify(data));
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setFilteredList: (
      state: CompanyState,
      action: PayloadAction<{ data: ICompany[]; isFavorites: boolean }>,
    ) => {
      state.filteredList = action.payload.data;
      state.filteredListIsFavorites = action.payload.isFavorites;
    },
    addFavorite: (state: CompanyState, action: PayloadAction<number>) => {
      if (!state.favorites.includes(action.payload)) {
        state.favorites = [...state.favorites, action.payload];
        saveFavorites(state.favorites);
      }
    },
    removeFavorite: (state: CompanyState, action: PayloadAction<number>) => {
      state.favorites = state.favorites.filter((f) => f !== action.payload);
      saveFavorites(state.favorites);
    },
  },
});

const companyReducer = slice.reducer;
const companySelector = (state: RootState) => state.company;

// Actions

const setFilteredList =
  (data: ICompany[], isFavorites: boolean = false): AppThunk =>
  (dispatch: AppDispatch) =>
    dispatch(slice.actions.setFilteredList({ data, isFavorites }));

const addFavorite =
  (id: number): AppThunk =>
  (dispatch: AppDispatch) =>
    dispatch(slice.actions.addFavorite(id));

const removeFavorite =
  (id: number): AppThunk =>
  (dispatch: AppDispatch) =>
    dispatch(slice.actions.removeFavorite(id));

export {
  companyReducer,
  companySelector,
  setFilteredList,
  addFavorite,
  removeFavorite,
};
