import { Text } from '@/components';
import cn from 'classnames';

interface Props {
  text: string;
  variant: string;
  selected?: boolean;
  onClick: (tag: string) => void;
  className?: string;
  reverse?: boolean;
}

const Tag: React.FC<Props> = ({
  text,
  variant = 'green',
  selected = false,
  className = '',
  reverse = false,
  onClick,
}: Props) => {
  const classes = cn({
    'rounded-full': true,
    'py-2.5': true,
    'px-5': true,
    'bg-green': variant === 'green',
    'bg-yellow': variant === 'yellow',
    'bg-blue': variant === 'blue',
    'bg-red': variant === 'red',
    'bg-white': variant === 'white',
    'shadow-selected': selected,
  });

  return (
    <button className={`${classes} ${className}`} onClick={() => onClick(text)}>
      <Text reverse={reverse} text={text} className='text-sm break-keep-all ' />
    </button>
  );
};

export default Tag;
