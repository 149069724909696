import { SearchIcon, CloseIcon } from '@/components';
import SearchFilter from './SearchFilter';

interface Props {
  value: string;
  score: number[];
  focus: boolean;
  onFocus: () => void;
  onClear: () => void;
  onSearch: () => void;
  onChange: (search: string) => void;
  onScoreChange: (score: number[]) => void;
}

const Search: React.FC<Props> = ({
  value,
  score,
  focus,
  onFocus,
  onClear,
  onSearch,
  onChange,
  onScoreChange,
}: Props) => {
  const searchFilterStyles = {
    top: '67px',
    height: 'calc((var(--vh, 1vh) * 100)  - 67px)',
    transform: focus ? 'translateY(0%)' : 'translateY(-150%)',
    opacity: focus ? 1 : 0,
    zIndex: 2000,
  };

  return (
    <>
      <div className='bg-orange-light p-1 rounded-full flex'>
        <SearchIcon />
        <input
          placeholder='キーワードや企業名を入れよう'
          className='bg-transparent mx-2 w-full outline-none text-sm'
          onClick={onFocus}
          onChange={(e) => onChange(e.target.value.trim())}
          value={value}
        />

        {focus && (
          <button onClick={onClear}>
            <CloseIcon />
          </button>
        )}
      </div>

      <div
        className={`absolute w-full left-0 transition-opacity`}
        style={searchFilterStyles}
      >
        <SearchFilter
          search={value}
          score={score}
          onScoreChange={onScoreChange}
          onSearch={onSearch}
          onSearchChange={onChange}
        />
      </div>
    </>
  );
};

export default Search;
