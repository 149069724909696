import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Building,
  NavBar,
  CompanyList,
  CompanyCard,
  SocialModal,
} from '@/components';
import { ICompany } from '@/state/company/interfaces';
import { companySelector } from '@/state/company/CompanySlice';

const BuildingPage = () => {
  const iframeStart = useRef() as React.MutableRefObject<HTMLIFrameElement>;
    const iframeCanvas = useRef() as React.MutableRefObject<HTMLIFrameElement>;
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);
  const { list, filteredList, filteredListIsFavorites } = useSelector(companySelector);
  const [showSocialModal, setShowSocialModal] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDetailsClick = (company: ICompany) => {
    setSelectedCompany(company);
    setOpenDialog(true);
  };

  const handleCompanySelected = (id: number) => {
    const company = list.find((c: ICompany) => c.id === id);
    if (company) {
      setSelectedCompany(company);
      setOpenDialog(true);
    }
  };

  const HandleHideSocialModal = () => {
    localStorage.setItem('showSocialModalShown', '1');
    setShowSocialModal(false);
  };

  if(iframeStart !== null) {
    console.log("test");
  }

  return (
    <div className='h-main overflow-y-auto'>
      <NavBar hidden={openDialog} iframeNavBar={iframeStart} />

      <Building
        companyList={filteredList}
        companyListIsFavorites={filteredListIsFavorites}
        onCompanySelected={handleCompanySelected}
        companyDetailOpened={openDialog}
        iframe={iframeCanvas}
      />

      {list !== filteredList && filteredList.length > 0 && (
        <CompanyList onDetailsClick={handleDetailsClick} hidden={openDialog} iframeCompanyList={iframeStart} iframeCanvas={iframeCanvas}/>
      )}

      {showSocialModal && <SocialModal onClick={HandleHideSocialModal} />}

      <div
        className={`fixed top-0 left-0 w-full h-full p-4 flex flex-col justify-end transition-opacity pointer-events-none ${
          openDialog ? 'opacity-100' : ' opacity-0'
        }`}
      >
        <button
          onClick={handleCloseDialog}
          className='absolute top-4 right-4 pointer-events-auto'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24.414'
            height='24.414'
            viewBox='0 0 24.414 24.414'
          >
            <g id='close' transform='translate(-334.793 -17.601)'>
              <line
                id='Line_81'
                x2='23'
                y2='23'
                transform='translate(335.5 18.308)'
                fill='none'
                stroke='#f7e7db'
                strokeWidth='2'
              />
              <line
                id='Line_82'
                x1='23'
                y2='23'
                transform='translate(335.5 18.308)'
                fill='none'
                stroke='#f7e7db'
                strokeWidth='2'
              />
            </g>
          </svg>
        </button>

        <div
          className={` bg-orange-light rounded-xl  ${
            openDialog ? 'pointer-events-auto' : 'pointer-events-none '
          }`}
        >
          {selectedCompany && (
            <CompanyCard
              id={selectedCompany.id}
              name={selectedCompany.title}
              creditScore={selectedCompany.trustScore}
              esgScore={selectedCompany.esgScore}
              hasImage={false}
              stockArrow={selectedCompany.stockArrow}
              icon={selectedCompany.icon}
              iframeCompanyCard={iframeStart}
              iframeCanvas={iframeCanvas}
              embedLink={selectedCompany.embedLink}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BuildingPage;
