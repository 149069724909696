const ArrowBackIcon: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='23.017'
      height='21.734'
      viewBox='0 0 23.017 21.734'
    >
      <g
        id='Component_30_9'
        data-name='Component 30 – 9'
        transform='translate(1.414 0.707)'
      >
        <g id='Group_540' data-name='Group 540' opacity='0.85'>
          <path
            id='Path_8'
            data-name='Path 8'
            d='M92.973,212.5l-10.16,10.16,10.16,10.16'
            transform='translate(-82.813 -212.5)'
            fill='none'
            stroke='#000'
            strokeWidth='2'
          />
          <line
            id='Line_55'
            data-name='Line 55'
            x2='21.602'
            transform='translate(0.001 10.16)'
            fill='none'
            stroke='#000'
            strokeWidth='2'
          />
        </g>
      </g>
    </svg>
  );
};

export default ArrowBackIcon;
