const MenuIcon: React.FC = () => {
  return (
    <svg
      id='menu'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='19'
      viewBox='0 0 24 19'
    >
      <defs>
        <clipPath id='clipPath'>
          <rect width='24' height='19' fill='none' />
        </clipPath>
      </defs>
      <g
        id='Repeat_Grid_19'
        data-name='Repeat Grid 19'
        clipPath='url(#clipPath)'
      >
        <g transform='translate(-332.5 -164)'>
          <line
            id='Line_27'
            data-name='Line 27'
            x2='25'
            transform='translate(332.5 165.5)'
            fill='none'
            stroke='#f7e7db'
            strokeWidth='2'
          />
        </g>
        <g transform='translate(-332.5 -156)'>
          <line
            id='Line_27-2'
            data-name='Line 27'
            x2='25'
            transform='translate(332.5 165.5)'
            fill='none'
            stroke='#f7e7db'
            strokeWidth='2'
          />
        </g>
        <g transform='translate(-332.5 -148)'>
          <line
            id='Line_27-3'
            data-name='Line 27'
            x2='25'
            transform='translate(332.5 165.5)'
            fill='none'
            stroke='#f7e7db'
            strokeWidth='2'
          />
        </g>
      </g>
    </svg>
  );
};

export default MenuIcon;
