interface Props {
  stars: number;
  selected?: number;
}

const StarsRating: React.FC<Props> = (props: Props) => {
  const { stars, selected = 0 } = props;

  const star = (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15.772'
      height='15'
      viewBox='0 0 15.772 15'
    >
      <path
        id='Path_56'
        data-name='Path 56'
        d='M7.886,0,5.449,4.938,0,5.729,3.943,9.572,3.012,15l4.874-2.562L12.759,15l-.931-5.427,3.943-3.843-5.449-.791Z'
        transform='translate(0 0)'
      />
    </svg>
  );

  return (
    <div className='flex'>
      {[...Array(stars).keys()].map((i) => {
        const opacity = i + 1 <= selected ? '' : 'opacity-20';
        const margin = i < stars - 1 ? 'mr-1' : '';

        return (
          <div key={'star' + i} className={`${margin} ${opacity}`}>
            {star}
          </div>
        );
      })}
    </div>
  );
};

export default StarsRating;
