/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import Fuse from 'fuse.js';
import { useSelector } from 'react-redux';

import {
  Logo,
  ArrowBackIcon,
  Search,
  Tags,
  Sidebar,
} from '@/components';
import styles from './NavBar.module.css';
import { companySelector, setFilteredList } from '@/state/company/CompanySlice';
import { useAppDispatch } from '@/state/store';

interface Props {
  hidden: boolean;
  iframeNavBar: React.MutableRefObject<HTMLIFrameElement>;
}

const NavBar: React.FC<Props> = ({ hidden, iframeNavBar }: Props) => {
  const dispatch = useAppDispatch();
  const [searchFocused, setSearchFocused] = useState(false);
  const [search, setSearch] = useState('');
  const [lastSearch, setLastSearch] = useState('');

  const [score, setScore] = useState<number[]>([1, 5]);
  const [lastScore, setLastScore] = useState<number[]>([1, 5]);

  const options = {
    includeScore: false,
    keys: ['title', 'category'],
  };

  const { list, filteredList, favorites  } = useSelector(companySelector);

  const fuse = new Fuse(list, options);

  const handleSearchFocus = () => {
    setSearchFocused(true);
  };

  const handleClearSearch = () => {
    setSearch('');
  };

  const handleBack = () => {
    if (lastSearch !== search) {
      setSearch(lastSearch);
    }

    if (lastScore !== score) {
      setScore(lastScore);
    }

    setSearchFocused(false);
  };

  //const handleLogoClick = () => {
  //  window.location.reload();
  //};

  const handleSearchChange = (search: string) => {
    setSearch(search);
  };

  const handleScoreChange = (score: number[]) => {
    setScore(score);
  };

  const handleTagsSelect = (tag: string) => {
    handleSearch(tag);
  };

  const handleSearch = (text: string) => {
    setSearch(text);
    setLastSearch(text);
    setLastScore(score);
    setSearchFocused(false);

    dispatch(
      setFilteredList(
        text !== '' ? fuse.search(text).map((d) => d.item) : list,
      ),
    );
  };

  const handleFavorites = () => {
    setSearchFocused(false);

    const results = list.filter((i) => favorites.includes(i.id));

    dispatch(setFilteredList(results, true));
  };

  const handleMenuItemClick = (name: string) => {
    switch (name) {
      case 'favourites':
        handleFavorites();
        break;
      case 'my-city':
        handleSearch('');
        break;
    }
  };

  useEffect(() => {
    handleSearch('');
  }, []);

  const showTags = list === filteredList || filteredList.length === 0;

  return (
    <div
      className={`absolute top-0 w-full transition-opacity ${
        hidden ? 'opacity-0' : 'opacity-100'
      }  ${styles.navBar} `}
    >
      <div className={`p-4 flex items-center z-10 relative ${searchFocused ? 'bg-orange-grey' : ''}`}>
        {searchFocused ? (
          <button onClick={handleBack}>
            <ArrowBackIcon />
          </button>
        ) : (
          <button onClick={() => handleSearch('')} className='text-left'>
            <Logo />
          </button>
        )}

        <div
          className={`flex-grow transition-all ${
            searchFocused ? 'ml-4' : 'mx-4'
          } `}
        >
          <Search
            value={search}
            score={score}
            focus={searchFocused}
            onChange={handleSearchChange}
            onFocus={handleSearchFocus}
            onClear={handleClearSearch}
            onSearch={() => handleSearch(search)}
            onScoreChange={handleScoreChange}
          />
        </div>
        <div className={`${searchFocused ? 'hidden' : ''}`}>
          <Sidebar onMenuItemClick={handleMenuItemClick} iframeSidebar={iframeNavBar} />
        </div>
      </div>
        {showTags && (
            <div className='w-full absolute overflow-x-scroll scrollbar-hide px-4 flex'>
                <Tags noWrap={true} onSelect={handleTagsSelect} selected={search} />
            </div>
      )}
    </div>
  );
};

export default NavBar;
