import { Tag } from '@/components';
import companyData from '../../../db/company-data.json';

interface Props {
    noWrap?: boolean;
  selected?: string;
  onSelect: (tag: string) => void;
}

const Tags: React.FC<Props> = ({
    noWrap = false,
  selected,
  onSelect,
}: Props) => {
    const toWrap = noWrap ? "flex-noWrap" : "flex-wrap";
  const ConsoleTest = (category: string) => () => {
    console.log(category);
  };

  let tags = companyData.reduce(
    (all: string[], curr: { category: string[] }) => {
      all = [...all, ...curr.category];
      return all;
    },
    [],
  );

  tags = [...new Set(tags)];

  const variants = ['green', 'blue', 'yellow', 'red'];

  return (
    <div className={`flex ${toWrap}`}>
      {tags.map((tag, i) => {
        return (
          <div className='mr-2 mb-2' key={i + tag}>
            <Tag
              text={tag}
              variant={variants[i % variants.length]}
              onClick={() => onSelect(tag)}
              selected={selected === tag}
            />
          </div>
        );
      })}
      <div className='mr-2 mb-2'>
          <Tag
            text="アート"
            variant={variants[2]}
            onClick={() => ConsoleTest("アート")}
            reverse={true}
            />
        </div>
        <div className='mr-2 mb-2'>
          <Tag
            text="フード"
            variant={variants[3]}
            onClick={() => ConsoleTest("フード")}
            reverse={true}
            />
        </div>
        <div className='mr-2 mb-2'>
          <Tag
            text="製造"
            variant={variants[0]}
            onClick={() => ConsoleTest("製造")}
            reverse={true}
            />
        </div>
        <div className='mr-2 mb-2'>
          <Tag
            text="エシカル"
            variant={variants[1]}
            onClick={() => ConsoleTest("エシカル")}
            reverse={true}
            />
        </div>
        <div className='mr-2 mb-2'>
          <Tag
            text="トラベル"
            variant={variants[2]}
            onClick={() => ConsoleTest("トラベル")}
            reverse={true}
            />
        </div>
        <div className='mr-2 mb-2'>
          <Tag
            text="アート"
            variant={variants[3]}
            onClick={() => ConsoleTest("アート")}
            reverse={true}
            />
        </div>
        <div className='mr-2 mb-2'>
          <Tag
            text="エンタメ"
            variant={variants[0]}
            onClick={() => ConsoleTest("エンタメ")}
            reverse={true}
            />
        </div>
        <div className='mr-2 mb-2'>
          <Tag
            text="金融"
            variant={variants[1]}
            onClick={() => ConsoleTest("金融")}
            reverse={true}
            />
        </div>
        <div className='mr-2 mb-2'>
          <Tag
            text="運輸"
            variant={variants[2]}
            onClick={() => ConsoleTest("test")}
            reverse={true}
            />
        </div>
    </div>
  );
};

export default Tags;