import { useState, useRef } from 'react';
import Slider from '@mui/material/Slider';
import { Text, StarsRating } from '@/components';

interface Props {
  score: number[];
  onScoreChange: (score: number[]) => void;
}

const HelpIcon = () => (
  <svg
    id='Group_593'
    data-name='Group 593'
    xmlns='http://www.w3.org/2000/svg'
    width='17'
    height='17'
    viewBox='0 0 17 17'
  >
    <g
      id='Ellipse_21'
      data-name='Ellipse 21'
      fill='none'
      stroke='#C5B8AF'
      strokeWidth='1'
    >
      <circle cx='8.5' cy='8.5' r='8.5' stroke='none' />
      <circle cx='8.5' cy='8.5' r='8' fill='none' />
    </g>
    <text
      id='_'
      data-name='?'
      transform='translate(4 13)'
      fontSize='14'
      fontFamily='Helvetica'
      fill='#C5B8AF' 
    >
      <tspan x='0' y='0'>
        ?
      </tspan>
    </text>
  </svg>
);


const Score: React.FC<Props> = ({ score, onScoreChange }: Props) => {
    const [open, setOpen] = useState(false);
    const target = useRef(null);
  const handleChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    const minDistance = 1;

    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      onScoreChange([Math.min(newValue[0], score[1] - minDistance), score[1]]);
    } else {
      onScoreChange([score[0], Math.max(newValue[1], score[0] + minDistance)]);
    }
  };

  const ESGHelp = () => (
    <div className="overlay">
      <div className='esgCard'>
          <button onClick={() => setOpen(!open)} className='esgClose'>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24.414'
                height='24.414'
                viewBox='0 0 24.414 24.414'
            >
                <g id='close' transform='translate(-334.793 -17.601)'>
                    <line
                        id='Line_81'
                        x2='23'
                        y2='23'
                        transform='translate(335.5 18.308)'
                        fill='none'
                        stroke='#000000'
                        strokeWidth='2'
                    />
                    <line
                        id='Line_82'
                        x1='23'
                        y2='23'
                        transform='translate(335.5 18.308)'
                        fill='none'
                        stroke='#000000'
                        strokeWidth='2'
                    />
                </g>
            </svg>
          </button>
          <svg xmlns="http://www.w3.org/2000/svg" width="343" height="161" viewBox="0 0 343 161">
              <rect id="Rectangle_458" data-name="Rectangle 458" width="343" height="161" rx="10" fill="#f7e7db"/>
              <text id="ESGスコア" transform="translate(22 35)" font-size="30" font-family="HiraginoSans-W7, Hiragino Sans" letter-spacing="-0.015em" opacity="0.85"><tspan x="0" y="33">ESGスコア</tspan></text>
              <text id="_仮_その企業が_社会や地球環境に対してどれくらい誠実かを表した数値です_" data-name="（仮）その企業が、社会や地球環境に対してどれくらい誠実かを表した数値です。" transform="translate(22 85)" font-size="14" font-family="HiraginoSans-W6, Hiragino Sans" opacity="0.85"><tspan x="0" y="15">（仮）その企業が、社会や地球環境に対してど</tspan><tspan x="0" y="37.4">れくらい誠実かを表した数値です。</tspan></text>
          </svg>
      </div>
    </div>
  );


  const handleQuestionClick = (type: string, payload: any = {}) => {
    setOpen(!open);
      console.log("ESG Question Clicked");
      console.log("Type: " + type + " payload: " + payload);
  };

  return (
    <>
      <div className='mb-2 flex items-center'>
        <Text text='ESGスコア' className='mr-2 text-sm text-black-lightGrey' />
        <button ref={target}
            onClick={() => {
                handleQuestionClick("test", {data: "companyList", isFavorites: "companyListIsFavorites",});
            }}
        >
            <HelpIcon />
        </button>
      </div>

      <div className='mb-2 flex item-baseline'>
        <StarsRating stars={5} />
        <Text text='〜' className='mx-2 text-black-lightGrey' />
        <StarsRating stars={5} />
      </div>

      <Slider
        value={score}
        step={1}
        valueLabelDisplay='off'
        min={1}
        max={5}
        onChange={handleChange}
        sx={{
          width: '90%',
          color: '#C5B8AF',//5BA291
          '& .MuiSlider-rail': {
            color: 'rgba(255,255,255,1)',
          },
          '& .MuiSlider-thumb': {
            color: '#000000',
          },
          '& .MuiSlider-thumb.Mui-active': {
            boxShadow: 'none',
          },
        }}
        marks
        disableSwap
      />
      {open && <ESGHelp />}
    </>
  );
};

export default Score;
