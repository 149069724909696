import { useState } from 'react';
import { Button, Text, StarsRating, FavoritePinIcon } from '@/components';
import {
  companySelector,
  addFavorite,
  removeFavorite,
} from '@/state/company/CompanySlice';
import { useAppDispatch } from '@/state/store';
import { useSelector } from 'react-redux';
import {AudioEquipmentIcon, CashierIcon, ConcertHallIcon, ConvenienceIcon, FacilityIcon, FanClub_CommunityIcon,
    FinanceIcon, FoodIcon, FridgeIcon, FundIcon, InstrumentsIcon, KaraokeIcon, LiveStreamIcon, LogisticIcon,
    MachineryIcon, MusicianIcon, MusicLabelIcon, PharmacyIcon, TicketingIcon} from '@/components';
import { CombiniThumbnail, CommunityThumbnail, Cashier3Thumbnail, Cashier1Thumbnail,
    BankingThumbnail, AudioEquipment2Thumbnail, AudioEquipment1Thumbnail, TicketingThumbnail,
    PharmacyThumbnail, MusicLabelThumbnail, MusiciansThumbnail, LogisticsThumbnail, 
    LiveStreamingThumbnail, Karaoke3Thumbnail, Karaoke1Thumbnail, InstrumentsThumbnail,
    Funds3Thumbnail, Funds2Thumbnail, Funds1Thumbnail, Fridge3Thumbnail, Fridge1Thumbnail,
    Food3Thumbnail, Food1Thumbnail, ConcertHallThumbnail } from '@/components';

interface Props {
  id: number;
  name: string;
  creditScore: number;
  esgScore: number;
  hasImage?: boolean; // If component does not have an image, the layout change a little bit
  className?: string;
  stockArrow: string;
  icon?: string;
  thumbnail?: string;
  iframeCompanyCard: React.MutableRefObject<HTMLIFrameElement>;
  iframeCanvas: React.MutableRefObject<HTMLIFrameElement>;
  embedLink: string;
  onDetailsClick?: () => void;
}

const IndustriesIcons = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='91.77'
    height='14.499'
    viewBox='0 0 91.77 14.499'
  >
    <g
      id='Group_569'
      data-name='Group 569'
      transform='translate(-203.149 -486.289)'
    >
      <text
        id='映画'
        transform='translate(221.183 498.788)'
        fontSize='12'
        fontFamily='HiraginoSans-W6, Hiragino Sans'
        opacity='0.85'
      >
        <tspan x='0' y='0'>
          映画
        </tspan>
      </text>
      <g id='movie' transform='translate(203.149 486.422)' opacity='0.85'>
        <rect
          id='Rectangle_312'
          data-name='Rectangle 312'
          width='12'
          height='9'
          rx='2'
          transform='translate(0 4)'
        />
        <path
          id='Polygon_10'
          data-name='Polygon 10'
          d='M2.636,1.481a1,1,0,0,1,1.728,0L6.123,4.5A1,1,0,0,1,5.259,6H1.741A1,1,0,0,1,.877,4.5Z'
          transform='translate(9 12) rotate(-90)'
        />
        <g
          id='Ellipse_56'
          data-name='Ellipse 56'
          transform='translate(1)'
          fill='none'
          stroke='#000'
          strokeWidth='1.5'
        >
          <circle cx='2.5' cy='2.5' r='2.5' stroke='none' />
          <circle cx='2.5' cy='2.5' r='1.75' fill='none' />
        </g>
        <g
          id='Ellipse_57'
          data-name='Ellipse 57'
          transform='translate(5)'
          fill='none'
          stroke='#000'
          strokeWidth='1.5'
        >
          <circle cx='2.5' cy='2.5' r='2.5' stroke='none' />
          <circle cx='2.5' cy='2.5' r='1.75' fill='none' />
        </g>
      </g>
    </g>
    <g
      id='Group_570'
      data-name='Group 570'
      transform='translate(-207.149 -486.422)'
    >
      <text
        id='金融'
        transform='translate(274.919 498.921)'
        fontSize='12'
        fontFamily='HiraginoSans-W6, Hiragino Sans'
        opacity='0.85'
      >
        <tspan x='0' y='0'>
          金融
        </tspan>
      </text>
      <g id='money' transform='translate(257.148 486.422)'>
        <path
          id='Union_2'
          data-name='Union 2'
          d='M-10741.5-4866a.5.5,0,0,1-.5-.5v-2a.5.5,0,0,1,.5-.5h1v-4h-1.5v-3l7.5-4,7.5,4v3h-1.5v4h1a.5.5,0,0,1,.5.5v2a.5.5,0,0,1-.5.5Zm11-3v-4h-3v4Zm-5,0v-4h-3v4Z'
          transform='translate(10742.001 4880)'
          opacity='0.85'
        />
      </g>
    </g>
  </svg>
);

const CompanyIcon = ({ size, icon }: { size: 'small' | 'default' , icon: string}) => {
    const imgSize = size === 'small' ? 55 : 65;
    return (
        <img className='mb-' src={icon} alt={''} width={imgSize} height={imgSize} />
    );
  };

const ArrowIcon = ({ size, arrowDirection }: { size: 'small' | 'default', arrowDirection: string }) => {
    const svgSize = size === 'small' ? 20 : 65;
    const opacitySwitch = size === 'small' ? '0.8' : '0.0';
    const arrowColour = size === 'small' ? '#F7E7DB' : '#252221';
    if(arrowDirection === 'up') {
        return (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={svgSize}
              height={svgSize}
              viewBox='0 0 65 65'
            >
              <g
                id='Group_600'
                data-name='Group 600'
                transform='translate(-392.344 -1899)'
              >
                <rect
                  id='Rectangle_322'
                  data-name='Rectangle 322'
                  width='65'
                  height='65'
                  rx='10'
                  transform='translate(392.344 1899)'
                  opacity={opacitySwitch}
                />
                <g
                  id='Group_333'
                  data-name='Group 333'
                  transform='translate(411.013 1907.562)'
                >
                  <path
                    id='Path_8'
                    data-name='Path 8'
                    d='M17.522,0,0,17.522,17.523,35.045'
                    transform='translate(38.735 24.781) rotate(135)'
                    fill='none'
                    stroke={arrowColour}
                    strokeWidth='6'
                  />
                  <line
                    id='Line_55'
                    data-name='Line 55'
                    x2='37.256'
                    transform='translate(26.344 12.391) rotate(135)'
                    fill='none'
                    stroke={arrowColour}
                    strokeWidth='6'
                  />
                </g>
              </g>
            </svg>
          );
    } else if(arrowDirection === 'down') {
        return (
            <svg
            xmlns='http://www.w3.org/2000/svg'
            width={svgSize}
            height={svgSize}
            viewBox='0 0 65 65'
          >
            <g
              id='Group_600'
              data-name='Group 600'
              transform='translate(-392.344 -1899)'
            >
              <rect
                id='Rectangle_322'
                data-name='Rectangle 322'
                width='65'
                height='65'
                rx='10'
                transform='translate(392.344 1899)'
                opacity={opacitySwitch}
              />
              <g
                id='Group_333'
                data-name='Group 333'
                transform='translate(411.013 1907.562)'
              >
                <path
                  id='Path_8'
                  data-name='Path 8'
                  d='M17.522,0,0,17.522,17.523,35.045'
                  transform='translate(14.735 50.781) rotate(225)'
                  fill='none'
                  stroke={arrowColour}
                  strokeWidth='6'
                />
                <line
                  id='Line_55'
                  data-name='Line 55'
                  x2='37.256'
                  transform='translate(27.044 38.391) rotate(225)'
                  fill='none'
                  stroke={arrowColour}
                  strokeWidth='6'
                />
              </g>
            </g>
          </svg>
        );
    } else {
        return (
            <svg
            xmlns='http://www.w3.org/2000/svg'
            width={svgSize}
            height={svgSize}
            viewBox='0 0 65 65'
          >
            <g
              id='Group_600'
              data-name='Group 600'
              transform='translate(-392.344 -1899)'
            >
              <rect
                id='Rectangle_322'
                data-name='Rectangle 322'
                width='65'
                height='65'
                rx='10'
                transform='translate(392.344 1899)'
                opacity={opacitySwitch}
              />
              <g
                id='Group_333'
                data-name='Group 333'
                transform='translate(411.013 1907.562)'
              >
				<path
                  id='Path_8'
                  data-name='Path 8'
                  d='M17.522,0,0,17.522,17.523,35.045'
                  transform='translate(30.735 40.721) rotate(180)'
                  fill='none'
                  stroke={arrowColour}
                  strokeWidth='6'
                />
                <line
                  id='Line_55'
                  data-name='Line 55'
                  x2='37.256'
                  transform='translate(30.044 23.191) rotate(180)'
                  fill='none'
                  stroke={arrowColour}
                  strokeWidth='6'
                />
              </g>
            </g>
          </svg>
        );
    }
};

const CompanyCard: React.FC<Props> = ({
  id,
  name,
  creditScore,
  esgScore,
  hasImage = true,
  className = '',
  stockArrow = 'up',
  icon = "Convenience",
  thumbnail = 'Combini',
  iframeCompanyCard,
  iframeCanvas,
  embedLink,
  onDetailsClick,
}: Props) => {
  const [openOverlay, setOpenOverlay] = useState(false);
  const { favorites } = useSelector(companySelector);
  const dispatch = useAppDispatch();
    const iconSize = hasImage ? "small" : "default";
    const icons: Record<string, string> = {
        "AudioEquipment" : AudioEquipmentIcon,
        "Cashier" : CashierIcon,
        "ConcertHall" : ConcertHallIcon,
        "Convenience" : ConvenienceIcon,
        "Facility" : FacilityIcon,
        "FanClub_Community" : FanClub_CommunityIcon,
        "Finance" : FinanceIcon,
        "Food" : FoodIcon,
        "Fridge" : FridgeIcon,
        "Fund" : FundIcon,
        "Instruments" : InstrumentsIcon,
        "Karaoke" : KaraokeIcon,
        "LiveStream" : LiveStreamIcon,
        "Logistic" : LogisticIcon,
        "Machinery" : MachineryIcon,
        "Musician" : MusicianIcon,
        "MusicLabel" : MusicLabelIcon,
        "Pharmacy" : PharmacyIcon,
        "Ticketing" : TicketingIcon
    }

    const thumbnails: Record<string, string> = {
        "Combini" : CombiniThumbnail,
        "Community" : CommunityThumbnail,
        "Cashier3" : Cashier3Thumbnail,
        "Cashier1" : Cashier1Thumbnail,
        "Banking" : BankingThumbnail,
        "AudioEquipment2" : AudioEquipment2Thumbnail,
        "AudioEquipment1" : AudioEquipment1Thumbnail,
        "Ticketing" : TicketingThumbnail,
        "Pharmacy" : PharmacyThumbnail,
        "MusicLabel" : MusicLabelThumbnail,
        "Musicians" : MusiciansThumbnail,
        "Logistics" : LogisticsThumbnail,
        "LiveStreaming" : LiveStreamingThumbnail,
        "Karaoke3" : Karaoke3Thumbnail,
        "Karaoke1" : Karaoke1Thumbnail,
        "Instruments" : InstrumentsThumbnail,
        "Funds3" : Funds3Thumbnail,
        "Funds2" : Funds2Thumbnail,
        "Funds1" : Funds1Thumbnail,
        "Fridge3" : Fridge3Thumbnail,
        "Fridge1" : Fridge1Thumbnail,
        "Food3" : Food3Thumbnail,
        "Food1" : Food1Thumbnail,
        "ConcertHall" : ConcertHallThumbnail
    };

    const sendMessageToBuilding = (type: string, payload: any = {}) => {
      if (iframeCanvas.current) {
        console.log('DEBUG::playcanvas event', {
          type,
          payload,
        });

        iframeCanvas.current.contentWindow?.postMessage(
          {
            type,
            payload,
          },
          'https://playcanv.as',
        );
      }
    };

  const title = (
    <div className='flex mb-4 items-center'>
        <CompanyIcon size={iconSize} icon={icons[icon]} />
        <Text text={name} className='text-2xl ml-2' />
    </div>
  );

  const details = (
    <>
      <div className='flex mb-2'>
        <div className='w-1/2'>
          <Text text='信用スコア' className='text-xs' />
        </div>
        <div className='w-1/2'>
          <StarsRating stars={5} selected={creditScore} />
        </div>
      </div>

      <div className='flex mb-2'>
        <div className='w-1/2'>
          <Text text='ESGスコア' className='text-xs' />
        </div>
        <div className='w-1/2'>
          <StarsRating stars={5} selected={esgScore} />
        </div>
      </div>

      <div className='flex mb-2'>
        <div className='w-1/2'>
          <Text text='その他の業種' className='text-xs' />
        </div>
        <div className='w-1/2'>
          <IndustriesIcons />
        </div>
      </div>
    </>
  );

  const imageLayoutDetails = (
    <>
      <div className='flex flex-col items-flex-start flex-shrink-0 mr-2 mt-2'>
        <Text text='信用スコア' className='text-xs' />
        <StarsRating stars={5} selected={creditScore} />
        <Text text='その他の業種' className='text-xs mt-4' />
        <IndustriesIcons />
      </div>

      <div className='flex flex-col items-flex-start flex-shrink-0 mr-2 mt-2'>
        <Text text='ESGスコア' className='text-xs' />
        <StarsRating stars={5} selected={esgScore} />
        <Text text={icon === "Fund" ? "一年間の価額変動" : "一年間の株価変動"} className='text-xs mt-4' />
        <ArrowIcon size='small' arrowDirection={stockArrow} />
      </div>
    </>
  );

  const fundDetails = (
    <>
      <div className='flex mb-3'>
        <Text text='日本の小売企業を集めた安定型商品' className='text-s' />
      </div>
    </>
  );

  const imageLayoutFundDetails = (
    <>
      <div className='flex flex-col mb-3'>
        <Text text={`日本の小売企業を集めた\n安定型商品`} className='text-s' />
        <Text text={icon === "Fund" ? "一年間の価額変動" : "一年間の株価変動"} className='text-xs' />
        <ArrowIcon size='small' arrowDirection={stockArrow} />
      </div>
    </>
  );

  const layoutWithImage = (
    <div className='flex flex-col'>
      <div className='flex'>
        <div className='icon'>
          <CompanyIcon size={iconSize} icon={icons[icon]} />
        </div>
        <Text text={name} className='text-2xl ml-2' />
      </div>
      <div className='flex'>
        <img src={thumbnails[thumbnail]} alt={name}  width={120} height={106} />
        {icon === "Fund" ? imageLayoutFundDetails : imageLayoutDetails}
      </div>
    </div>
  );

  const layoutWithOutImage = (
    <div className=''>
      {title}
      <div className='flex'>
        <div className='flex-grow'>
          {icon === "Fund" ? fundDetails : details}
        </div>
        <div className='self-end'>
          <ArrowIcon size='default' arrowDirection={stockArrow} />
          <Text text={icon === "Fund" ? "一年間の価額変動" : "一年間の株価変動"} className='text-xxs mt-2' />
        </div>
      </div>
    </div>
  );

  const inFavorites = favorites.includes(id);

  const handleFavorite = () => {
    if (inFavorites) {
      dispatch(removeFavorite(id));
    } else {
      dispatch(addFavorite(id));
    }
  };

  const Overlay = () => {
    return (
      <div className="overlay">
        <div className='productPage'>
            <button onClick={() => {setOpenOverlay(false); sendMessageToBuilding('web:overlayClosed', {});}} className='productPageClose'>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24.414'
                    height='24.414'
                    viewBox='0 0 24.414 24.414'
                >
                    <g id='close' transform='translate(-334.793 -17.601)'>
                        <line
                            id='Line_81'
                            x2='23'
                            y2='23'
                            transform='translate(335.5 18.308)'
                            fill='none'
                            stroke='#F7E7DB'
                            strokeWidth='2'
                        />
                        <line
                            id='Line_82'
                            x1='23'
                            y2='23'
                            transform='translate(335.5 18.308)'
                            fill='none'
                            stroke='#F7E7DB'
                            strokeWidth='2'
                        />
                    </g>
                </svg>
            </button>
            <iframe
            ref={iframeCompanyCard}
            title='iframeOverlay'
            style={{ height: '100%', width: '100%' }}
            height='100%'
            width='100%'
            src={embedLink}
            frameBorder='0'
            ></iframe>
        </div>
      </div>    
    );
  };
/* <div className="overflow-y-scroll mt-6">
    <ProductPage title={name} description={"Description"} stockArrow={stockArrow} category={icon} date={""} stockValue={""} thumbnail={thumbnails[thumbnail]} icon={icons[icon]} />
  </div> */

  return (
    <>
      {openOverlay && <Overlay />}
      <div className={`py-4 px-6 ${className}`}>
        {hasImage ? layoutWithImage : layoutWithOutImage}
        <div className='flex items-center mt-2 justify-between'>
          <Button
            text='詳しく見る'
            variant='primary'
            onClick={() => {
              setOpenOverlay(true); 
              sendMessageToBuilding('web:overlayOpened', {});
            }}
            className='w-full'
          />

          <button className='ml-4' onClick={handleFavorite}>
            <FavoritePinIcon active={inFavorites} />
          </button>
        </div>
        
      </div>
    </>
  );
};

export default CompanyCard;
