/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { ICompany } from '@/state/company/interfaces';
import { useSelector } from 'react-redux';
import { companySelector } from '@/state/company/CompanySlice';

interface Props {
  companyList: ICompany[];
  companyListIsFavorites: boolean;
  companyDetailOpened: boolean;
  iframe: React.MutableRefObject<HTMLIFrameElement>;
  onCompanySelected: (id: number) => void;
}

const Building: React.FC<Props> = ({
  companyList,
  companyListIsFavorites,
  companyDetailOpened,
  iframe,
  onCompanySelected,
}: Props) => {
  //const iframeCanvas = useRef() as React.MutableRefObject<HTMLIFrameElement>;
  const { list } = useSelector(companySelector);

  const sendMessageToBuilding = (type: string, payload: any = {}) => {
      console.log("Building iFrame of type: " + type);
      console.log(iframe);
    if (iframe.current) {
      console.log('DEBUG::playcanvas event', {
        type,
        payload,
      });

      iframe.current.contentWindow?.postMessage(
        {
          type,
          payload,
        },
        'https://toppan.netlify.app/',
      );
    }
  };

  const handleWindowMessage = (e: MessageEvent) => {
    const { type, payload } = e.data;

    switch (type) {
      case 'openCompanyDetails':
        onCompanySelected(payload.id);
        break;
      case 'getCompanyList':
        sendMessageToBuilding('web:searchResults', {
          data: list,
          isFavorites: companyListIsFavorites,
        });
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleWindowMessage, false);

  }, []);

  useEffect(() => {
    sendMessageToBuilding('web:searchResults', {
      data: companyList,
      isFavorites: companyListIsFavorites,
    });
  }, [companyList]);

  useEffect(() => {
    sendMessageToBuilding('web:companyModalStatus', {
      open: companyDetailOpened,
    });
  }, [companyDetailOpened]);

  return (
    <div className={`h-full w-full`} style={{ touchAction: 'none' }}>
        <iframe
          ref={iframe}
          title='building'
          style={{ height: '100%', width: '100%' }}
          height='100%'
          width='100%'
          src={`https://toppan.netlify.app/toppanprototype/`}
          frameBorder='0'
          allow='accelerometer;
              autoplay;
              clipboard-write;
              encrypted-media;
              gyroscope;
              picture-in-picture'
        ></iframe>
    </div>
  );
};

export default Building;
