const SearchIcon: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='27'
      height='27'
      viewBox='0 0 27 27'
    >
      <circle
        id='Ellipse_59'
        data-name='Ellipse 59'
        cx='13.5'
        cy='13.5'
        r='13.5'
        fill='#252221'
        opacity='0.2'
      />
      <g
        id='Group_551'
        data-name='Group 551'
        transform='translate(-69.183 -19.402)'
      >
        <g
          id='Ellipse_60'
          data-name='Ellipse 60'
          transform='translate(75.183 25.402)'
          fill='none'
          stroke='#fff'
          strokeWidth='2'
        >
          <circle cx='6' cy='6' r='6' stroke='none' />
          <circle cx='6' cy='6' r='5' fill='none' />
        </g>
        <line
          id='Line_80'
          data-name='Line 80'
          x2='3.378'
          y2='3.378'
          transform='translate(85.035 35.254)'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeWidth='2'
        />
      </g>
    </svg>
  );
};

export default SearchIcon;
