import { Text } from '@/components';

const Category: React.FC = () => {
  return (
    <>
      <Text text='商品種類' className='mb-2 text-sm' />
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='185.594'
        height='20'
        viewBox='0 0 185.594 20'
      >
        <circle
          id='Ellipse_79'
          data-name='Ellipse 79'
          cx='5'
          cy='5'
          r='5'
          transform='translate(5 5)'
          opacity='0.85'
        />
        <text
          id='全て'
          transform='translate(23.719 14.5)'
          fontSize='13'
          fontFamily='HiraginoSans-W6, Hiragino Sans'
          opacity='0.85'
        >
          <tspan x='0' y='0'>
            全て
          </tspan>
        </text>
        <text
          id='株'
          transform='translate(85.156 14.5)'
          fontSize='13'
          fontFamily='HiraginoSans-W6, Hiragino Sans'
          opacity='0.85'
        >
          <tspan x='0' y='0'>
            株
          </tspan>
        </text>
        <text
          id='投資信託'
          transform='translate(133.594 14.5)'
          fontSize='13'
          fontFamily='HiraginoSans-W6, Hiragino Sans'
          opacity='0.85'
        >
          <tspan x='0' y='0'>
            投資信託
          </tspan>
        </text>
        <g
          id='Ellipse_76'
          data-name='Ellipse 76'
          fill='none'
          stroke='#000'
          strokeWidth='1'
          opacity='0.2'
        >
          <circle cx='10' cy='10' r='10' stroke='none' />
          <circle cx='10' cy='10' r='9.5' fill='none' />
        </g>
        <g
          id='Ellipse_77'
          data-name='Ellipse 77'
          transform='translate(61.438)'
          fill='none'
          stroke='#000'
          strokeWidth='1'
          opacity='0.2'
        >
          <circle cx='10' cy='10' r='10' stroke='none' />
          <circle cx='10' cy='10' r='9.5' fill='none' />
        </g>
        <g
          id='Ellipse_78'
          data-name='Ellipse 78'
          transform='translate(109.875)'
          fill='none'
          stroke='#000'
          strokeWidth='1'
          opacity='0.2'
        >
          <circle cx='10' cy='10' r='10' stroke='none' />
          <circle cx='10' cy='10' r='9.5' fill='none' />
        </g>
      </svg>
    </>
  );
};

export default Category;
