const CloseIcon: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='27'
      height='27'
      viewBox='0 0 27 27'
    >
      <circle
        id='Ellipse_63'
        data-name='Ellipse 63'
        cx='13.5'
        cy='13.5'
        r='13.5'
        fill='#252221'
        opacity='0.85'
      />
      <g id='close' transform='translate(8.5 8.5)'>
        <line
          id='Line_81'
          data-name='Line 81'
          x2='10'
          y2='10'
          fill='none'
          stroke='#f7e7db'
          strokeLinecap='round'
          strokeWidth='1.5'
        />
        <line
          id='Line_82'
          data-name='Line 82'
          x1='10'
          y2='10'
          fill='none'
          stroke='#f7e7db'
          strokeLinecap='round'
          strokeWidth='1.5'
        />
      </g>
    </svg>
  );
};

export default CloseIcon;
