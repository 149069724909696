import { Button, Text, Tags } from '@/components';
import Category from './Category';
import Score from './Score';

interface Props {
  score: number[];
  search: string;
  onSearch: () => void;
  onSearchChange: (search: string) => void;
  onScoreChange: (score: number[]) => void;
}

const SearchFilter: React.FC<Props> = ({
  score,
  search,
  onSearch,
  onSearchChange,
  onScoreChange,
}: Props) => {
  return (
    <div className='w-full h-full bg-orange-light flex flex-col overflow-x-hidden'>
      <div className='flex-grow  overflow-auto'>
        <div className='m-4'>
          <Text text='詳細検索' className='text-lg' />
        </div>

        <hr className='border-t border-black opacity-10' />

        <div className='m-4 mb-8'>
          <Category />
        </div>

        <div className='m-4 mb-8'>
          <Score score={score} onScoreChange={onScoreChange} />
        </div>

        <div className='m-4 mb-8'>
          <Text text='テーマを選ぶ' className='mb-2 text-sm' />
            <Tags onSelect={(tag) => onSearchChange(tag)} selected={search} />
        </div>
      </div>

      <div className='bg-black py-5 text-center'>
        <Button
          variant='primary'
          text='検索する'
          onClick={onSearch}
          className='px-20'
        />
      </div>
    </div>
  );
};

export default SearchFilter;
