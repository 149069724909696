interface Props {
  onClick: () => void;
}

const SocialModal: React.FC<Props> = ({ onClick }: Props) => {
  return (
    <div
      className={`fixed bg-black-50 top-0 left-0 w-full h-full p-4 flex justify-center items-center `}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='275'
        height='510'
        viewBox='0 0 275 510'
        onClick={onClick}
      >
        <g
          id='Group_580'
          data-name='Group 580'
          transform='translate(-120.509 -222.066)'
          opacity='0.2'
        >
          <text
            id='Hello_New_Vision'
            data-name='Hello
New
Vision'
            transform='translate(169.509 261.066)'
            fill='#fff'
            fontSize='50'
            fontFamily='Helvetica-Bold, Helvetica'
            fontWeight='700'
            opacity='0.999'
          >
            <tspan x='0' y='0'>
              HELLO
            </tspan>
            <tspan x='0' y='55'>
              NEW
            </tspan>
            <tspan x='0' y='110'>
              VISION
            </tspan>
          </text>
        </g>
        <g id='Group_581' data-name='Group 581' transform='translate(-50 -43)'>
          <rect
            id='Rectangle_38'
            data-name='Rectangle 38'
            width='275'
            height='55'
            rx='10'
            transform='translate(50 241.972)'
            fill='#00cf1d'
          />
          <g
            id='TYPE_A'
            data-name='TYPE A'
            transform='translate(-311.851 -3.631)'
          >
            <g
              id='Group_62'
              data-name='Group 62'
              transform='translate(377.059 256.905)'
            >
              <path
                id='Path_29'
                data-name='Path 29'
                d='M411.059,270.7c0-7.607-7.626-13.8-17-13.8s-17,6.189-17,13.8c0,6.82,6.048,12.531,14.217,13.611.554.119,1.307.365,1.5.838a3.5,3.5,0,0,1,.055,1.537s-.2,1.2-.243,1.455c-.074.43-.342,1.681,1.473.916s9.79-5.765,13.357-9.87h0a12.274,12.274,0,0,0,3.644-8.488'
                transform='translate(-377.059 -256.905)'
                fill='#fff'
              />
              <g
                id='Group_61'
                data-name='Group 61'
                transform='translate(5.577 10.12)'
              >
                <path
                  id='Path_30'
                  data-name='Path 30'
                  d='M409.481,282.914h-1.193a.331.331,0,0,0-.331.33v7.408a.331.331,0,0,0,.331.33h1.193a.331.331,0,0,0,.331-.33v-7.408a.331.331,0,0,0-.331-.33'
                  transform='translate(-401.512 -282.914)'
                  fill='#00cf1d'
                />
                <path
                  id='Path_31'
                  data-name='Path 31'
                  d='M422.42,282.914h-1.193a.331.331,0,0,0-.331.33v4.4l-3.394-4.584a.376.376,0,0,0-.026-.034l0,0-.02-.021-.006-.005-.017-.015-.009-.007-.017-.012-.01-.006-.017-.01-.011-.005-.019-.008-.011,0-.019-.006-.012,0-.019,0-.014,0-.018,0h-1.221a.331.331,0,0,0-.331.33v7.408a.331.331,0,0,0,.331.33h1.193a.331.331,0,0,0,.331-.33v-4.4l3.4,4.59a.335.335,0,0,0,.084.082l0,0,.02.012.01.005.016.008.016.007.01,0,.023.007h0a.337.337,0,0,0,.085.011h1.193a.331.331,0,0,0,.331-.33v-7.408a.331.331,0,0,0-.331-.33'
                  transform='translate(-406.244 -282.914)'
                  fill='#00cf1d'
                />
                <path
                  id='Path_32'
                  data-name='Path 32'
                  d='M396.486,289.127h-3.24v-5.883a.331.331,0,0,0-.331-.331h-1.193a.331.331,0,0,0-.331.331v7.406h0a.329.329,0,0,0,.093.229l0,.005,0,0a.328.328,0,0,0,.229.092h4.764a.331.331,0,0,0,.331-.331v-1.193a.331.331,0,0,0-.331-.331'
                  transform='translate(-391.392 -282.913)'
                  fill='#00cf1d'
                />
                <path
                  id='Path_33'
                  data-name='Path 33'
                  d='M441.892,284.769a.331.331,0,0,0,.33-.331v-1.193a.331.331,0,0,0-.33-.331h-4.764a.329.329,0,0,0-.23.093l0,0-.005.006a.328.328,0,0,0-.092.228h0v7.406h0a.328.328,0,0,0,.093.229l0,.005,0,0a.329.329,0,0,0,.229.093h4.764a.331.331,0,0,0,.33-.331v-1.193a.331.331,0,0,0-.33-.331h-3.24v-1.252h3.24a.331.331,0,0,0,.33-.331v-1.192a.331.331,0,0,0-.33-.332h-3.24v-1.252Z'
                  transform='translate(-419.13 -282.914)'
                  fill='#00cf1d'
                />
              </g>
            </g>
          </g>
          <text
            id='LINEで登録'
            transform='translate(187.5 275.472)'
            fill='#fff'
            fontSize='16'
            fontFamily='HiraginoSans-W7, Hiragino Sans'
          >
            <tspan x='-44.256' y='0'>
              LINEで登録
            </tspan>
          </text>
        </g>
        <g
          id='Group_582'
          data-name='Group 582'
          transform='translate(-50 27.028)'
        >
          <rect
            id='Rectangle_38-2'
            data-name='Rectangle 38'
            width='275'
            height='55'
            rx='10'
            transform='translate(50 241.972)'
            fill='#00acee'
          />
          <text
            id='Twitterで登録'
            transform='translate(187.5 275.472)'
            fill='#fff'
            fontSize='16'
            fontFamily='HiraginoSans-W7, Hiragino Sans'
          >
            <tspan x='-55.304' y='0'>
              Twitterで登録
            </tspan>
          </text>
          <path
            id='Path_116'
            data-name='Path 116'
            d='M84.289,122.452c11.146,0,17.242-9.234,17.242-17.242,0-.262,0-.523-.018-.783a12.329,12.329,0,0,0,3.023-3.137,12.1,12.1,0,0,1-3.481.953,6.081,6.081,0,0,0,2.664-3.352,12.143,12.143,0,0,1-3.848,1.471,6.066,6.066,0,0,0-10.327,5.527,17.2,17.2,0,0,1-12.489-6.331,6.064,6.064,0,0,0,1.876,8.089,6.015,6.015,0,0,1-2.75-.759v.077a6.062,6.062,0,0,0,4.862,5.94,6.052,6.052,0,0,1-2.736.1,6.067,6.067,0,0,0,5.662,4.208,12.16,12.16,0,0,1-7.526,2.6A12.341,12.341,0,0,1,75,119.73a17.156,17.156,0,0,0,9.289,2.717'
            transform='translate(-7.329 158.52)'
            fill='#fff'
          />
        </g>
        <text
          id='or'
          transform='translate(135 337.028)'
          fill='#fff'
          fontSize='25'
          fontFamily='Helvetica-Bold, Helvetica'
          fontWeight='700'
          opacity='0.7'
        >
          <tspan x='-12.5' y='19'>
            or
          </tspan>
        </text>
        <g id='Group_584' data-name='Group 584' transform='translate(-50 -46)'>
          <g
            id='Group_583'
            data-name='Group 583'
            transform='translate(0 174.028)'
          >
            <rect
              id='Rectangle_38-3'
              data-name='Rectangle 38'
              width='275'
              height='55'
              rx='10'
              transform='translate(50 241.972)'
              fill='#fff'
            />
            <text
              id='メールアドレスで登録'
              transform='translate(191.5 275.472)'
              fontSize='16'
              fontFamily='HiraginoSans-W7, Hiragino Sans'
              opacity='0.85'
            >
              <tspan x='-79.12' y='0'>
                メールアドレスで登録
              </tspan>
            </text>
          </g>
          <rect
            id='Rectangle_335'
            data-name='Rectangle 335'
            width='25'
            height='19'
            rx='4'
            transform='translate(68 434)'
            opacity='0.85'
          />
          <path
            id='Path_117'
            data-name='Path 117'
            d='M2090.2,447.179l14.449,7.777,14.566-8.335'
            transform='translate(-2024 -9.695)'
            fill='none'
            stroke='#fff'
            strokeLinejoin='round'
            strokeWidth='2'
          />
        </g>
        <g
          id='Group_583-2'
          data-name='Group 583'
          transform='translate(-21.652 223.028)'
        >
          <rect
            id='Rectangle_38-4'
            data-name='Rectangle 38'
            width='219'
            height='45'
            rx='10'
            transform='translate(49.652 241.972)'
            fill='#ddd'
          />
          <text
            id='登録せずに始める'
            transform='translate(159.152 270.472)'
            fontSize='16'
            fontFamily='HiraginoSans-W7, Hiragino Sans'
            opacity='0.85'
          >
            <tspan x='-63.76' y='0'>
              登録せずに始める
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
};

export default SocialModal;
