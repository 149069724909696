import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import routes, { IRoute } from '@/config/routes';
import useVH from '@/utils/hooks/useVH';
import { isMobile } from 'react-device-detect';
import { store } from '@/state/store';
import { DesktopView } from './components';

function App() {
  useVH();

  if (!isMobile) {
    return <DesktopView />;
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          {routes.map((route: IRoute) => (
            <Route
              key={route.path}
              path={route.path}
              element={route.component}
            />
          ))}
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
