interface Props {
  active?: boolean;
}

const FavoritePinIcon: React.FC<Props> = ({ active = false }: Props) => {
  return (
    <svg
      id='Group_298'
      data-name='Group 298'
      xmlns='http://www.w3.org/2000/svg'
      width='45'
      height='45'
      viewBox='0 0 45 45'
    >
      <circle id='Ellipse_37' cx='22.5' cy='22.5' r='22.5' fill='#fff' />
      <g
        id='Group_297'
        data-name='Group 297'
        transform='translate(25.017 7.45) rotate(45)'
        opacity={active ? '1' : '0.2'}
      >
        <path
          id='Union_1'
          data-name='Union 1'
          d='M17.714,18.53H.01C0,18.377,0,18.229,0,18.091a9.364,9.364,0,0,1,2.6-6.526A8.822,8.822,0,0,1,5.639,9.489V4.028H4.432A2.014,2.014,0,0,1,4.432,0h8.862a2.014,2.014,0,1,1,0,4.028H12.085V9.489a8.823,8.823,0,0,1,3.044,2.076,9.364,9.364,0,0,1,2.6,6.526c0,.145,0,.292-.01.438h0Z'
          fill={active ? '#f57577' : 'none'}
          stroke={active ? '#f57577' : '#000'}
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          id='Path_90'
          d='M0,0V5.508'
          transform='translate(8.862 19.336)'
          fill='none'
          stroke='#000'
          strokeLinecap='round'
          strokeWidth='2'
        />
      </g>
    </svg>
  );
};

export default FavoritePinIcon;
