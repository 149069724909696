import { BuildingPage, NotFoundPage } from '@/components';

interface IRoute {
  path: string;
  component: JSX.Element;
}

const routes: IRoute[] = [
  {
    path: '/',
    component: <BuildingPage />,
  },
  {
    path: '*',
    component: <NotFoundPage />,
  },
];

export default routes;
export type { IRoute };
