const Logo: React.FC = () => {
  return (
    <div
      className='text-orange-light text-left inline-block'
      style={{ fontSize: '10px', lineHeight: '11px' }}
    >
      STOCK
      <br />
      CITY
      <br />
      DEMO
    </div>
  );
};

export default Logo;
