import cn from 'classnames';

interface Props {
  text: string;
  textColor?: string;
  className?: string;
  variant?: 'default' | 'primary' | 'green-light';

  onClick: () => void;
}

const Button: React.FC<Props> = (props: Props) => {
  const {
    text,
    textColor,
    className = '',
    variant = 'default',
    onClick,
  } = props;

  const classes = cn({
    'rounded-xl': true,
    'bg-white': variant === 'default',
    'bg-green-light': variant === 'primary',
    'p-3.5': true,
    'text-white': variant === 'primary',
    'text-green': textColor === 'green',
    'text-yellow': textColor === 'yellow',
    'text-blue': textColor === 'blue',
    'text-red': textColor === 'red',
    'font-W7': true,
  });

  return (
    <button onClick={onClick} className={`${classes} ${className}`}>
      {text}
    </button>
  );
};

export default Button;
