import cn from 'classnames';

interface Props {
  text: string;
  font?: 'W6' | 'W7';
  className?: string;
  reverse?: boolean;
}

const Text: React.FC<Props> = (props: Props) => {
  const { text, font = 'W6', className = '', reverse = false, } = props;

  const classes = cn({
    'whitespace-pre-wrap': true,
    'font-W6': font === 'W6',
    'font-W7': font === 'W7',
  });
  if(reverse) return <div className={`${classes} ${className} text-reverse`}>{text}</div>;
  else return <div  className={`${classes} ${className}`}>{text}</div>;
};

export default Text;
