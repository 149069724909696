import { Text } from '@/components';
import QRCode from 'react-qr-code';

const DesktopView = () => {
  return (
    <div className='bg-orange-light h-main flex justify-center items-center'>
      <div className='w-6/12'>
        <QRCode
          size={130}
          value= 'https://stockcity.wktokyo.com'
          bgColor='transparent'
          className='mx-auto'
        />

        <Text
          text='This is a mobile-only experience. Please scan the QR code on a mobile device to open this link.'
          className='text-center mt-8'
        />
      </div>
    </div>
  );
};

export default DesktopView;
