import { useState } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Text, CompanyCard } from '@/components';
import { ICompany } from '@/state/company/interfaces';
import { useSelector } from 'react-redux';
import { companySelector } from '@/state/company/CompanySlice';

interface Props {
  hidden: boolean;
  iframeCompanyList: React.MutableRefObject<HTMLIFrameElement>;
  iframeCanvas: React.MutableRefObject<HTMLIFrameElement>;
  onDetailsClick(company: ICompany): void;
}

const CompanyList: React.FC<Props> = ({ hidden, iframeCompanyList, iframeCanvas, onDetailsClick }: Props) => {
  const [open, setOpen] = useState(false);
  const { filteredList } = useSelector(companySelector);

  console.log("filteredList");
  console.log(filteredList);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const TestConsole = (i: ICompany) => () =>  {
    console.log("ICompnay Below");
    console.log(i);
  }

  const header = (
    <div
      className='absolute left-0 top-0  w-full rounded-t-xl bg-orange-light visible  flex justify-center items-center border-b  border-black-15'
      style={{ height: '50px', top: '-50px' }}
    >
      <div className='flex items-center'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18.5'
          height='13'
          viewBox='0 0 18.5 13'
        >
          <defs>
            <clipPath id='clip-path'>
              <rect width='18.5' height='13' fill='none' />
            </clipPath>
          </defs>
          <g
            id='Repeat_Grid_103'
            data-name='Repeat Grid 103'
            clipPath='url(#clip-path)'
          >
            <g transform='translate(-123 -603)'>
              <circle
                id='Ellipse_62'
                data-name='Ellipse 62'
                cx='1.5'
                cy='1.5'
                r='1.5'
                transform='translate(123 603)'
                opacity='0.2'
              />
              <line
                id='Line_83'
                data-name='Line 83'
                x2='12'
                transform='translate(128.5 604.5)'
                fill='none'
                stroke='#000'
                strokeLinecap='round'
                strokeWidth='2'
                opacity='0.25'
              />
            </g>
            <g transform='translate(-123 -598)'>
              <circle
                id='Ellipse_62-2'
                data-name='Ellipse 62'
                cx='1.5'
                cy='1.5'
                r='1.5'
                transform='translate(123 603)'
                opacity='0.2'
              />
              <line
                id='Line_83-2'
                data-name='Line 83'
                x2='12'
                transform='translate(128.5 604.5)'
                fill='none'
                stroke='#000'
                strokeLinecap='round'
                strokeWidth='2'
                opacity='0.25'
              />
            </g>
            <g transform='translate(-123 -593)'>
              <circle
                id='Ellipse_62-3'
                data-name='Ellipse 62'
                cx='1.5'
                cy='1.5'
                r='1.5'
                transform='translate(123 603)'
                opacity='0.2'
              />
              <line
                id='Line_83-3'
                data-name='Line 83'
                x2='12'
                transform='translate(128.5 604.5)'
                fill='none'
                stroke='#000'
                strokeLinecap='round'
                strokeWidth='2'
                opacity='0.25'
              />
            </g>
          </g>
        </svg>

        <Text text='企業一覧' className='ml-2 text-sm' />
      </div>
    </div>
  );

  const body = (
    <div
      className='bg-orange-light overflow-y-scroll'
      style={{ maxHeight: 'calc((var(--vh, 1vh) * 100)  - 115px)' }}
    >
      {filteredList.map((res, i) => (
        <CompanyCard
          key={i}
          id={res.id}
          name={res.title}
          creditScore={res.trustScore}
          esgScore={res.esgScore}
          className=' border-b border-black-15'
          stockArrow={res.stockArrow}
          icon={res.icon}
          thumbnail={res.thumbnail}
          iframeCompanyCard={iframeCompanyList}
          iframeCanvas={iframeCanvas}
          embedLink={res.embedLink}
          //onDetailsClick={() => onDetailsClick(res)}
          onDetailsClick={() => TestConsole(res)}
        />
      ))}
    </div>
  );

  return (
    <SwipeableDrawer
      anchor='bottom'
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      disableSwipeToOpen={false}
      className={`${hidden ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}
      swipeAreaWidth={50}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        zIndex: 1,
        '& .MuiPaper-root': {
          overflow: 'visible',
        },
      }}
    >
      {header}

      {body}
    </SwipeableDrawer>
  );
};

export default CompanyList;
