import { useState, useEffect, useRef, memo } from 'react';

import { MenuIcon, Text, BlackCat } from '@/components';

interface Props {
  iframeSidebar: React.MutableRefObject<HTMLIFrameElement>;
  onMenuItemClick: (name: string) => void;
}
  
interface OProps {
  embedLink: string;
}


const Sidebar: React.FC<Props> = ({ iframeSidebar, onMenuItemClick }: Props) => {
  const [open, setOpen] = useState(false);
  const [openOverlay, setOpenOverlay] = useState(false);
  const [openAboutOverlay, setOpenAboutOverlay] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const btnClasses = 'px-4 py-5 text-black-95 text-xs uppercase';

  const handleClick = (name: string) => {
    setOpen(false);

    onMenuItemClick(name);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (open && ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [open]);

  const Overlay = ( { embedLink }: OProps ) => {
    //var iframes = document.querySelectorAll('iframe');
    //if(iframes != null) {
    //  for (var i = 0; i < iframes.length; i++) {
    //    let temp = iframes[i].parentNode;
    //    if(temp) temp.removeChild(iframes[i]);
    //  }
    //}
    return (
      <div className="overlay">
        <div className='productPage'>
            <button onClick={() => {setOpenOverlay(false); setOpenAboutOverlay(false)}} className='productPageClose'>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24.414'
                    height='24.414'
                    viewBox='0 0 24.414 24.414'
                >
                    <g id='close' transform='translate(-334.793 -17.601)'>
                        <line
                            id='Line_81'
                            x2='23'
                            y2='23'
                            transform='translate(335.5 18.308)'
                            fill='none'
                            stroke='#F7E7DB'
                            strokeWidth='2'
                        />
                        <line
                            id='Line_82'
                            x1='23'
                            y2='23'
                            transform='translate(335.5 18.308)'
                            fill='none'
                            stroke='#F7E7DB'
                            strokeWidth='2'
                        />
                    </g>
                </svg>
            </button>
            <iframe
            ref={iframeSidebar}
            title='iframeOverlay'
            style={{ height: '100%', width: '95%' }}
            height='100%'
            width='100%'
            src={embedLink}
            frameBorder='0'
            ></iframe>
        </div>
      </div>
    );
  };

  return (
    <div>
      <button
        onClick={() => {
          setOpen(true);
        }}
      >
        <MenuIcon />
      </button>

      <div
        className={`absolute min-h-screen top-0 left-0 right-0 transition-transform transform overflow-hidden flex justify-end ${
          open ? 'translate-x-0 w-full' : ' translate-x-full w-0'
        }`}
      > 
        <div className='absolute w-1/2 pl-2 pt-8'>
          <div className='flex justify-center'>
            <img src={BlackCat} alt={''}  width='64' height='64'/>
          </div>
        </div>
        <div className='bg-orange-light flex flex-col w-1/2 h-screen ' ref={ref}>
          <div className='text-center mt-4'>
            <div className='flex justify-center mb-4'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='90'
                height='90'
                viewBox='0 0 90 90'
                preserveAspectRatio="xMidYMin"
              >
                <circle
                  id='Ellipse_59'
                  data-name='Ellipse 59'
                  cx='45'
                  cy='45'
                  r='45'
                  fill='#252221'
                  opacity='0.2'
                />
              </svg>
            </div>
            <Text text='株村 投信' className={'text-xl'}/>
            <Text text='プロフィールを編集する' className={'text-xxs text-black-lightGrey'}/>
          </div>

          <hr className='mt-4 border-black-95' />

          <nav className='flex flex-col'>
            <button
              className={`px-4 py-4 text-black-95 text-xs uppercase bg-green-sidebar`}
              onClick={() => handleClick('my-city')}
            >
              <Text className='text-left' text='TOP' />
            </button>
            <button
              className={`${btnClasses} bg-yellow-sidebar`}
              onClick={() => handleClick('favourites')} 
            >
              <Text className='text-left' text='マイシティ' />
            </button>
            <button
              className={`${btnClasses} bg-blue-sidebar`}
              onClick={() => setOpenOverlay(true)}
            >
              <Text className='text-left' text='証券会社リスト' />
            </button>
            {openOverlay && <Overlay embedLink={"https://xd.adobe.com/embed/d235aefa-ec04-4669-a97c-949b9915efbc-8766/"}/>}
            <button
              className={`${btnClasses} bg-red-sidebar`}
              onClick={() => setOpenAboutOverlay(true)}
            >
              <Text className='text-left' text='資産形成のいろは' />
            </button>
            {openAboutOverlay && <Overlay embedLink={"https://xd.adobe.com/embed/698d7dcf-792e-475c-937e-2a7a7540121d-3cde/"}/>}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default memo(Sidebar);
